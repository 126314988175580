import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/components/tools/Index';
import Login from '@/components/pages/Login';
import User from '@/components/pages/User';
import AccessGroup from '@/components/pages/AccessGroup';
import store from '@/store/index';
import ClassOfService from '@/components/pages/ClassOfService';
import SalesConfiguration from '@/components/pages/SalesConfiguration';
import Flight from '@/components/pages/Flight';
import Station from '@/components/pages/Station';
import Fare from '@/components/pages/Fare';
import Tax from '@/components/pages/Tax';
import Fee from '@/components/pages/Fee';
import FeePrice from '@/components/pages/FeePrice';
import SSR from '@/components/pages/SSR';
import Currency from '@/components/pages/Currency';
import EditFlight from '@/components/pages/EditFlight';
import SyncFlight from '@/components/pages/SyncFlight';
import Location from '@/components/pages/Location';
import PaymentMethod from '@/components/pages/PaymentMethod';
import FareType from '@/components/pages/FareType';
import ProductClass from '@/components/pages/ProductClass';
import BookingSearch from '@/components/pages/BookingSearch';
import Booking from '@/components/booking/Booking';
import Checkin from '@/components/checkin/Checkin';
import CheckinFlight from '@/components/checkin/CheckinFlight';
import ChangePassword from '@/components/pages/ChangePassword';
import PaxList from '@/components/reports/PaxList';
import TRList from '@/components/reports/TRList';
import CUBAApiList from '@/components/reports/CUBAApiList'; 
import BagList from '@/components/reports/BagList';
import UserHistory from '@/components/reports/UserHistory';
import CreditFile from '@/components/reports/CreditFile';
import CashSummary from '@/components/reports/CashSummary';
import FlightLoad from '@/components/reports/FlightLoad';
import AccountsSummary from '@/components/reports/AccountsSummary';
import FlightClose from '@/components/checkin/FlightClose';
import OperFlight from '@/components/checkin/OperFlight';
import MovePassengers from "@/components/pages/MovePassengers";
import EmailConfiguration from "@/components/pages/EmailConfiguration";
import SalesByClassMarket from '@/components/reports/SalesByClassMarket';
import SalesByPaymentMethod from '@/components/reports/SalesByPaymentMethod';
import SalesByLocationPayment from '@/components/reports/SalesByLocationPayment';
import SalesByLocation from '@/components/reports/SalesByLocation';
import TicketsDetail from '@/components/reports/TicketsDetail';
import Organization from '@/components/pages/Organization';
import SalesByClassFlight from '@/components/reports/SalesByClassFlight';
import Customer from '@/components/pages/Customer';
import AddManyFlights from '@/components/pages/AddManyFlights';


Vue.use(Router)

const router = new Router({
  routes: [
    { path: '*', redirect: '/'},
    { path: '/', component: Index, props: true, label: 'Home'},
    { path: '/home', component: Index, props: true},
    { path: '/login', component: Login},
    { path: '/user', name: 'user', component: User, label: 'Usuarios'},
    { path: '/accessgroup', name: 'accessgroup', component: AccessGroup, label: 'Permisos de Usuario'},
    { path: '/classofservice', name: 'classofservice', component: ClassOfService, label: 'Clases de Servicio'},
    { path: '/salesconfiguration', name: 'salesconfiguration', component: SalesConfiguration, label: 'Configuración de Venta'},
    { path: '/flight', name: 'flight', component: Flight, label: 'Vuelos'},
    { path: '/station', name: 'station', component: Station, label: 'Escalas'},
    { path: '/fare', name: 'fare', component: Fare, label: 'Tarifas'},
    { path: '/tax', name: 'tax', component: Tax, label: 'Tasas e Impuestos'},
    { path: '/ssr', name: 'ssr', component: SSR, label: 'SSR'},
    { path: '/fee', name: 'fee', component: Fee, label: 'Cargos'},
    { path: '/feeprice', name: 'feeprice', component: FeePrice, label: 'Precios de Extras'},
    { path: '/currency', name: 'currency', component: Currency, label: 'Monedas'},
    { path: '/editflight', name: 'editflight', component: EditFlight, label: 'Vuelos'},
    { path: '/addmany', name: 'addmany', component: AddManyFlights, label: 'Agregar Por Período'},
    { path: '/syncflight', name: 'syncflight', component: SyncFlight, label: 'Sincronizar Vuelo'},
    { path: '/movepassengers', name: 'movepassengers', component: MovePassengers, label: 'Mover Pasajeros'},
    { path: '/location', name: 'location', component: Location, label: 'Ubicaciones'},
    { path: '/paymentmethod', name: 'paymentmethod', component: PaymentMethod, label: 'Métodos de Pago'},
    { path: '/booking_search', name: 'booking_search', component: BookingSearch, label: 'Buscar Reservas'},
    { path: '/faretype', name: 'faretype', component: FareType, label: 'Tipos de Tarifas'},
    { path: '/productclass', name: 'productclass', component: ProductClass, label: 'Clases de Producto'},
    { path: '/booking', name: 'booking', component: Booking, props: true, label: 'Rerervas'},
    { path: '/checkin', name: 'checkin', component: Checkin, label: 'Checkin'},
    { path: '/checkinflight', name: 'checkinflight', component: CheckinFlight, props: true, label: 'Checkin Vuelos'},
    { path: '/changepassword', name: 'changepassword', component: ChangePassword},
    { path: '/reports/paxlist', name: 'reports/paxlist', component: PaxList, label: 'Listado de Pasajeros'},
    { path: '/reports/trlist', name: 'reports/trlist', component: TRList, label: 'Listado Travel Rock'},
    { path: '/reports/cuba_api_list', name: 'reports/cuba_api_list', component: CUBAApiList, label: 'Listado API CUBA'},
    { path: '/reports/baglist', name: 'reports/baglist', component: BagList, label: 'Listado de Equipajes'},
    { path: '/reports/userhistory', name: 'reports/userhistory', component: UserHistory, label: 'Historial de Usuario'},
    { path: '/reports/creditfile', name: 'reports/creditfile', component: CreditFile, label: 'Credit File'},
    { path: '/reports/cashsummary', name: 'reports/cashsummary', component: CashSummary, label: 'Cierre de Caja'},
    { path: '/reports/flightload', name: 'reports/flightload', component: FlightLoad, label: 'Ocupación de Vuelo'},
    { path: '/reports/accountssummary', name: 'reports/accountssummary', component: AccountsSummary, label: 'Cuenta Corriente'},
    { path: '/flight_close', name: 'flight_close', component: FlightClose, label: 'Lista Cierre de Vuelos'},
    { path: '/email', name: 'email', component: EmailConfiguration, label: 'Configuración de emails'},
    { path: '/organization', name: 'organization', component: Organization, label: 'Organización'},
    { path: '/customer', name: 'customer', component: Customer, label: 'Cliente'},
    { path: '/operflight', name: 'operflight', component: OperFlight, label: 'Cierre de Vuelos', props: true},
    { path: '/reports/salesbyclassmarket', name: 'reports/salesbyclassmarket', component: SalesByClassMarket, label: 'Ventas por Clase/Segmento'},
    { path: '/reports/salesbypaymentmethod', name: 'reports/salesbypaymentmethod', component: SalesByPaymentMethod, label: 'Ventas por Medio de Pago'},
    { path: '/reports/salesbylocationpayment', name: 'reports/salesbylocationpayment', component: SalesByLocationPayment, label: 'Ventas por Ubicación/Medio de Pago'},
    { path: '/reports/salesbylocation', name: 'reports/salesbylocation', component: SalesByLocation, label: 'Ventas por Ubicación'},
    { path: '/reports/ticketsdetail', name: 'reports/ticketsdetail', component: TicketsDetail, label: 'Detalle de Tickets'},
    { path: '/reports/salesbyclassflight', name: 'reports/salesbyclassflight', component: SalesByClassFlight, label: 'Ventas por Clase/Vuelo'},
  ]
})

router.beforeEach(async function(to, from, next)  {
    if (to.path=='/login') {
        next();
    } else {
        if (!store.state.user) {
            let user = await app.getUser();
            if (!user) {
                next('/login');
                return;
            } else {
                store.commit('setUser', user);
                store.dispatch('setStations');
                store.dispatch('setFees');
                if (user.ChangePassword && to.path!='/changepassword') {
                    next('/changepassword');
                    return;
                }
            }
        }
        if (store.state.user.ChangePassword && to.path!='/changepassword') {
            next('/changepassword');
            return;
        }
        if (to.path=='/changepassword') {
            next();
        } else if (to.path=='/') {
            next();
        } else if (app.canAccess(store.state.user, 'view','GET', to.path)) {
            next();
        } else {
            next('/');
        }
    }

})

export default router;