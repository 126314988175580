<template>
    <div v-if="booking">
        <div class="form-row">
            <div class="col-md-10">
                <div class="card" v-for="(pax, i) in booking.Passengers">
                    <div class="card-header card-row" @click="editPaxInfo(pax, i)">
                        <div class="form-row">
                            <a>
                                <div class="col-md-12">
                                    {{getPassengerData(pax)}}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-row">
                    <input type="checkbox" id="Group" v-model="booking.Group" :disabled="!canEditGroup">
                    <label class="form-check-label">Grupo</label>
                </div>
                <div class="form-row" v-if="canImportPax">
                    <button type="button" class="btn btn-primary button-row" @click="openFile">Cargar Excel</button>
                    <input id="file-input" type="file" name="name" style="display: none;" @change="excelToJSON" />
                </div>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <editpassenger v-if="currentPax" :paxkey="paxKey" :currentpax="currentPax"
            @close="close" @updatepax="updatePax"></editpassenger>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import XLSX from 'xlsx';
import editpassenger from '@/components/booking/EditPassenger'

var moment = require('moment');
export default {
  name: 'bookingpassengers',
  components: {
    editpassenger
  },
  data  () {
    return {
        currentPax: null,
        paxKey: null,
        canEditGroup: true,
        canImportPax: true,
        json_object: null
    }
  },
  mounted () {
    this.canEditGroup = app.canAccess(this.user, 'api','POST', '/booking/group/', false);
    this.canImportPax = app.canAccess(this.user, 'api','POST', '/booking/import_pax/', false);
  },
  computed: {
    ...mapState({
        user: state => state.user,
        booking: state => state.booking,
    }),
  },
  methods: {
    getPassengerData (pax) {
        let res = app.getPassengerData(pax);
        if (pax.Infant && pax.Infants[0]) {
            let inf = ' (INF: ';
            if (pax.Infants[0].LastName && pax.Infants[0].FirstName) {
                inf += pax.Infants[0].LastName + ', ' + pax.Infants[0].FirstName;
            }
            if (pax.Infants[0].MiddleName) inf += ' ' + pax.Infants[0].MiddleName;
            inf += ')'
            res += inf;
        }
        return res;
    },
    editPaxInfo (pax, i) {
        this.currentPax = null;
        this.paxKey = i;
        let self = this;
        setTimeout(function(){
            self.currentPax = Object.assign({}, pax);
            //self.$nextTick(function () {
              //$('#editpassenger').modal('show')
            //})

        }, 100);

    },
    close () {
        this.currentPax = null;
        this.paxKey = null;
        //$('#editpassenger').modal('hide');
    },
    updatePax (paxInfo, i) {
        for (let f in paxInfo) {
            this.booking.Passengers[i][f] = paxInfo[f];
        }
        if (i==0) {
            if (!this.booking.ContactInformation[0].FirstName) {
                this.booking.ContactInformation[0].FirstName = paxInfo.FirstName;
            }
            if (!this.booking.ContactInformation[0].MiddleName) {
                this.booking.ContactInformation[0].MiddleName = paxInfo.MiddleName;
            }
            if (!this.booking.ContactInformation[0].LastName) {
                this.booking.ContactInformation[0].LastName = paxInfo.LastName;
            }
        }
        this.currentPax = null;
        this.paxKey = null;
        //this.$emit("update:booking", Object.assign({}, this.booking));
        //$('#editpassenger').modal('hide');

    },
    openFile () {
       $('#file-input').trigger('click');
    },
    parseExcel (file) {
        let self =  this;
        var reader = new FileReader();

        reader.onload = function(e) {
          var data = e.target.result;
          var workbook = XLSX.read(data, {
            type: 'binary'
          });

          workbook.SheetNames.forEach(function(sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            var json_object = JSON.stringify(XL_row_object);
            self.setPassengers(JSON.parse(json_object))

          })

        };

        reader.onerror = function(ex) {
          console.log(ex);
        };

        reader.readAsBinaryString(file);
    },
    excelToJSON (e) {
      this.parseExcel(e.target.files[0]);
    },
    setPassengers (paxList) {
        for (let i in paxList) {
            for (let fieldname in paxList[i]) {
                if (fieldname=='DocNumber') {
                    if (paxList[i].DocNumber && typeof paxList[i].DocNumber === 'number') {
                        paxList[i].DocNumber = paxList[i].DocNumber.toString();
                    }
                    paxList[i].DocNumber = paxList[i].DocNumber.replace(/\./g,'')
                };
                if (fieldname=='Gender') {
                    if (paxList[i].Gender[0] == 'F') paxList[i].Gender = 'FEMALE';
                    if (paxList[i].Gender[0] == 'M') paxList[i].Gender = 'MALE';
                }
                if (['DOB', 'DocExpiryDate', 'DocIssueDate'].indexOf(fieldname)>-1) {
                    let value = paxList[i][fieldname];
                    if (typeof value === 'string') {
                        paxList[i][fieldname] = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
                    } else {
                        paxList[i][fieldname] = moment(this.excelDateToJSDate(paxList[i][fieldname])).format("YYYY-MM-DD");
                    }
                }
                if (fieldname=='PaxType') {
                    if (paxList[i][fieldname]!='ADT' && paxList[i][fieldname]!='CHD') {
                        alert('Tipo de Pasajero Incorrecto. Solo ser permite ADT o CHD');
                        return;
                    }
                }
                if (!this.booking.Passengers[i]) return;
                if (fieldname=='Seat') {
                    for (let segment of this.booking.Passengers[i].Segments) {
                        for (let leg of segment.Legs) {
                            leg.UnitDesignator = paxList[i][fieldname];
                        }
                    }
                } else {
                    this.booking.Passengers[i][fieldname] = paxList[i][fieldname];
                }
            }
            if (!this.booking.Passengers[i].PaxType) this.booking.Passengers[i].PaxType = 'ADT';
            if (!this.booking.Passengers[i].DocTypeCode) this.booking.Passengers[i].DocTypeCode = 'DNI';
        }
    },
    excelDateToJSDate (serial) {
       return new Date((serial - (25567 + 1))*86400*1000);
    }

  },
}
</script>

