<template>
    <div>
      <report-window endpoint="/reports/cuba_api_list"
          :fields="fields"
          :title="title"
          :headers="headers"
          :templates="templates"
          perPage="500"
          :afterRun="afterRun"
          :headerFields="headerFields"
      ></report-window>
      <boardings-print :show.sync="showBoardings" :list="list" v-if="showBoardings"></boardings-print>

    </div>
</template>

<script>
import '@/css/app.css';

import ReportWindow from '@/components/tools/ReportWindow'

import { mapState } from 'vuex';
var moment = require('moment');
export default {
  name: 'CubaApiList',
  components: {
    ReportWindow,
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
  },
  data () {
    return {
        showBoardings: false,
        list: [],
        fields: [
            {name: 'FlightNr', editor: 'text', label: 'Vuelo', columns: 1, required: true},
            {name: 'FlightDate', editor: 'date', label: 'Fecha', defValue: moment(new Date()).format("YYYY-MM-DD")
                , required: true, columns: 1},
            {name: 'DepartureStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Desde'},
            {name: 'ArrivalStation', relation: 'station', optionLabels: 'id'
                , editor: 'select', label: 'Hacia'},
        ],
        title: 'Listado API CUBA',
        headers: {
            FirstName: 'First Name',
            LastName: 'Last Name',
            MiddleName: 'Middle Name',
            DOB: 'Date of Birth',
            CountryOfBirth: 'Country of Birth',
            DepartureStation: 'Embarque',
            ArrivalStation: 'Desembarque',
            DocType: 'Document Type',
            DocNumber: 'Document Number',
            DocExpiryDate: 'Document Expiry Date',
            DocIssueCountry: 'Doc Issue Country',
            CountryResidence: 'Country Residence',
            Nationality: 'Nationality',
        },
        templates: [
        ],
        headerFields: [],
    }
  },
  methods: {
      afterRun (report) {
          /*if (report.list && report.list.length>0) {
              this.headerFields = []
              this.headerFields.push({label: 'fecha vuelo', value: moment(report.list[0].DepartureDate).format('DD/MM/YYYY')})
              this.headerFields.push({label: 'hora vuelo', value: moment(report.list[0].STD).format("HH:mm")})
              this.headerFields.push({label: 'numero de vuelo', value: report.list[0].FlightNumber})
              this.headerFields.push({label: 'cliente', value: 'Travel Rock'})
              this.headerFields = Object.assign([], this.headerFields);
          }*/
      },
  }

}
</script>

